// client/src/components/faculty/viewStudentAttendance/Body.js

import React, { useEffect, useState } from "react";
import BoyIcon from "@mui/icons-material/Boy";
import { useDispatch, useSelector } from "react-redux";
import { getSubject } from "../../../redux/actions/adminActions";
import { getStudentsWithAttendance } from "../../../redux/actions/facultyActions";
import { MenuItem, Select } from "@mui/material";
import Spinner from "../../../utils/Spinner";
import * as classes from "../../../utils/styles";
import { SET_ERRORS } from "../../../redux/actionTypes";

const Body = () => {
  const dispatch = useDispatch();
  const departments = useSelector((state) => state.admin.allDepartment);
  const subjects = useSelector((state) => state.admin.subjects);
  const students = useSelector((state) => state.faculty.studentsWithAttendance);

  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state);

  const [value, setValue] = useState({
    department: "",
    year: "",
    section: "",
  });

  const [search, setSearch] = useState(false);
  const [subjectId, setSubjectId] = useState("");

  useEffect(() => {
    if (Object.keys(store.errors).length !== 0) {
      setError(store.errors);
      setLoading(false);
    }
  }, [store.errors]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSearch(true);
    setLoading(true);
    setError({});
    console.log('Form values on submit:', value);
    try {
      await dispatch(getSubject(value));
    } catch (error) {
      alert("Failed to fetch subjects. Please try again." + error.message);
      console.error("Error in API call:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubjectChange = async (e) => {
    setSubjectId(e.target.value);
    setLoading(true);
    setError({});
    try {
      await dispatch(
        getStudentsWithAttendance({
          subjectId: e.target.value,
          department: value.department,
          year: value.year,
          section: value.section,
        })
      );
    } catch (error) {
      alert("Failed to fetch students. Please try again." + error.message);
      console.error("Error in API call:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("students", students);
    if (students?.length !== 0) setLoading(false);
  }, [students]);

  useEffect(() => {
    dispatch({ type: SET_ERRORS, payload: {} });
  }, [dispatch]);

  useEffect(() => {
    if (subjects.length === 0)
        dispatch(
            getStudentsWithAttendance({
              subjectId: "",
              department: value.department,
              year: value.year,
              section: value.section,
            })
          );
  }, [subjects]);

  return (
    <div className="flex-1 mt-3">
      <div className="space-y-5">
        <div className="flex text-gray-400 items-center space-x-2">
          <BoyIcon />
          <h1>Student Attendance Percentages</h1>
        </div>
        <div className="mr-10 bg-white rounded-xl pt-6 pl-6 h-auto flex flex-row gap-2">
            <div className="flex flex-col">
                {/* Form to select department, year, and semester */}
                <form className="flex flex-col space-y-2" onSubmit={handleSubmit}>
                    <label htmlFor="department">Department</label>
                    <Select
                    required
                    displayEmpty
                    sx={{ height: 36, width: 224 }}
                    inputProps={{ "aria-label": "Without label" }}
                    value={value.department}
                    onChange={(e) =>
                    {
                        console.log('Department selected:', e.target.value);
                        setValue({ ...value, department: e.target.value })
                    }  
                    }
                    >
                    <MenuItem value="">None</MenuItem>
                    {departments?.map((dp, idx) => (
                        <MenuItem key={idx} value={dp.department}>
                        {dp.department}
                        </MenuItem>
                    ))}
                    </Select>
                    <label htmlFor="year">Year</label>
                    <Select
                    required
                    displayEmpty
                    sx={{ height: 36, width: 224 }}
                    inputProps={{ "aria-label": "Without label" }}
                    value={value.year}
                    onChange={(e) => setValue({ ...value, year: e.target.value })}
                    >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="2023">2023</MenuItem>
                    <MenuItem value="2024">2024</MenuItem>
                    <MenuItem value="2025">2025</MenuItem>
                    </Select>
                    <label htmlFor="section">Semester</label>
                    <Select
                    required
                    displayEmpty
                    sx={{ height: 36, width: 224 }}
                    inputProps={{ "aria-label": "Without label" }}
                    value={value.section}
                    onChange={(e) => setValue({ ...value, section: e.target.value })}
                    >
                    <MenuItem value="">None</MenuItem>
                    {[...Array(8)].map((_, idx) => (
                        <MenuItem key={idx} value={`${idx + 1}`}>
                        {idx + 1}
                        </MenuItem>
                    ))}
                    </Select>

                    <button
                    className={`${classes.adminFormSubmitButton} w-56`}
                    type="submit"
                    >
                    Search
                    </button>
                </form>

                {/* Display subject list after form submission */}
                {search && !loading && (
                    <div className="mt-4">
                    <label className="font-bold text-lg">Subject</label>
                    <Select
                        required
                        displayEmpty
                        sx={{ height: 36, width: 224 }}
                        inputProps={{ "aria-label": "Without label" }}
                        value={subjectId}
                        onChange={handleSubjectChange}
                    >
                        <MenuItem value="">None</MenuItem>
                        {subjects?.map((subject, idx) => (
                        <MenuItem key={idx} value={subject._id}>
                            {subject.subjectName}
                        </MenuItem>
                        ))}
                    </Select>
                    </div>
                )}
            </div>

             {/* Display student attendance percentages */}
          <div className={classes.tableDataContainer}>
            <div className={classes.loadingAndError}>
              {loading && (
                <Spinner
                  message="Loading"
                  height={50}
                  width={150}
                  color="#111111"
                  messageColor="blue"
                />
              )}
              {error.noStudentError && (
                <p className="text-red-500 text-2xl font-bold">
                  {error.noStudentError}
                </p>
              )}
            </div>
            {students && students.length > 0 && !loading && (
              <div className={`${classes.adminData} h-fit`}>
                <div className="grid grid-cols-6">
                  <h1 className={`col-span-1 ${classes.adminDataHeading}`}>
                    Sr no.
                  </h1>
                  <h1 className={`col-span-1 ${classes.adminDataHeading}`}>
                    Roll No
                  </h1>
                  <h1 className={`col-span-2 ${classes.adminDataHeading}`}>
                    Name
                  </h1>
                  <h1 className={`col-span-1 ${classes.adminDataHeading}`}>
                    Semester
                  </h1>
                  <h1 className={`col-span-1 ${classes.adminDataHeading}`}>
                    Attendance %
                  </h1>
                </div>
                {students.map((stu, idx) => (
                  <div
                    key={idx}
                    className={`${classes.adminDataBody} grid-cols-6`}
                  >
                    <h1 className={`col-span-1 ${classes.adminDataBodyFields}`}>
                      {idx + 1}
                    </h1>
                    <h1 className={`col-span-1 ${classes.adminDataBodyFields}`}>
                      {stu.rollNo}
                    </h1>
                    <h1 className={`col-span-2 ${classes.adminDataBodyFields}`}>
                      {stu.name}
                    </h1>
                    <h1 className={`col-span-1 ${classes.adminDataBodyFields}`}>
                      {stu.section}
                    </h1>
                    <h1
                      className={`col-span-1 ${classes.adminDataBodyFields} ${
                        stu.attendancePercentage < 75
                          ? "text-red-600"
                          : "text-green-600"
                      }`}
                    >
                      {stu.attendancePercentage}%
                    </h1>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
