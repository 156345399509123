import React, { useState, useRef, useEffect } from "react";

import { Drawer, Button, Placeholder } from "rsuite";
import "rsuite/Drawer/styles/index.css";

import { useDispatch } from "react-redux";
import {
  acceptFeeRequest,
  modifyFeeRequest,
} from "../../../redux/actions/accountActions";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import generateInvoice from "../utils/generateInvoice";

function Card({ fee }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const linkRef = useRef(null);
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openWithHeader, setOpenWithHeader] = useState(false);
  const [remark, setRemark] = useState("");
  const handleSettlementAccept = async () => {
    try {
      await dispatch(acceptFeeRequest({ fee: fee._id, remarks: remark }));
      alert("Fee Request Accepted");
      window.location.reload();
    } catch (error) {
      alert(`Error: ${error.message}`);
      console.error("Error accepting fee request:", error);
    }
  };
  const handleSettlementReject = async () => {
    try {
      await dispatch(modifyFeeRequest({ fee: fee._id, remarks: remark }));
      alert("Fee Request Rejected and send to pending");
      window.location.reload();
    } catch (error) {
      alert("Fee Request Rejected" + error.message);
      console.log(error);
    }
  };

  const handleGenerateInvoice = async (fee) => {
    try {
      const url = await generateInvoice(fee);
      if (url != null) setInvoiceUrl(url);
    } catch (error) {
      console.error("Error generating invoice:", error);
      alert(`Error: ${error.message}`); 
    }
  };

  useEffect(() => {
    if (invoiceUrl && linkRef.current) {
      linkRef.current.click();
    }
  }, [invoiceUrl]);

  return (
    <div className="w-[200px] h-fit bg-white drop-shadow-lg flex flex-col justify-between gap-5 p-3">
      <div className="grid grid-cols-1">
        <div className="flex text-[10px] gap-4">
          <label className="font-bold" htmlFor="">
            Id
          </label>
          <p>{fee.student._id}</p>
        </div>
        <div className="flex text-[10px] gap-4">
          <label className="font-bold" htmlFor="">
            Amount
          </label>
          <p>{fee.amount}</p>
        </div>
        <div className="flex text-[10px] gap-4">
          <label className="font-bold" htmlFor="">
            Date of Payment
          </label>
          <p>{fee.createdDate}</p>
        </div>
        <div className="flex text-[10px] gap-4">
          <label className="font-bold" htmlFor="">
            Proof
          </label>
          <p>{fee.proof}</p>
        </div>
        <div className="flex text-[10px] gap-4">
          <label className="font-bold" htmlFor="">
            Remarks
          </label>
          <p>{fee.remarks}</p>
        </div>
        <button
          className="text-sm w-full bg-blue-500 hover:bg-blue-700 text-white py-1  rounded"
          onClick={() => {
            handleGenerateInvoice(fee);
          }}
        >
          Download Invoice
        </button>

        {invoiceUrl && (
          <a
            ref={linkRef}
            href={invoiceUrl}
            download="invoice.pdf"
            className="hidden"
          >
            Download Invoice
          </a>
        )}
      </div>
      <div className="w-full flex justify-center"></div>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Drawer.Body>
          <Placeholder.Paragraph />
        </Drawer.Body>
      </Drawer>

      <Drawer open={openWithHeader} onClose={() => setOpenWithHeader(false)}>
        <Drawer.Header>
          <Drawer.Title>Fee Settlement</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpenWithHeader(false)}>Cancel</Button>
            {/* <Button
              onClick={() => setOpenWithHeader(false)}
              appearance="primary"
            >
              Confirm
            </Button> */}
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <label htmlFor="remark">Remark</label>
          <textarea
            id="remark"
            name="remark"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            className="border-[1px] rounded border-neutral-200 focus:outline-none p-2 w-full"
            type="text"
          />
          <div className="flex justify-between py-5">
            <button
              onClick={handleSettlementAccept}
              className="bg-blue-500 px-4 py-1 text-sm text-white rounded"
            >
              Accept
            </button>
            <button
              onClick={handleSettlementReject}
              className="bg-red-500 px-4 py-1 text-sm text-white rounded"
            >
              Reject
            </button>
          </div>
        </Drawer.Body>
      </Drawer>
    </div>
  );
}

export default Card;
