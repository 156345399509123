import { useState, useEffect } from "react";
import { getFacultyLeavesForAdmin, updateFacultyLeave } from "../../../redux/api";

export default function Body() {
    const user = JSON.parse(localStorage.getItem("user"));
    const [pendingleavesData, setPendingLeavesData] = useState([]);
    const [acceptedLeavesData, setAcceptedLeavesData] = useState([]);
    const [rejectedLeavesData, setRejectedLeavesData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleAcceptRejectLeave = async(id, action) => {
        setLoading(true);
        try {
            const admin = user.result._id;
            const remarks = null;
            const { data, status } = await updateFacultyLeave(id, action, remarks, admin);
            if (status === 200) {
                console.log("update success", data.response);
                alert(`update success`);
            }
        } catch (e) {
            console.log(e.response);
            alert(e.response.data.message);
        }
        setLoading(false);
    }

    const fetchFacultyLeavesForAdmin = async(mode) => {
        let { data, status } = await getFacultyLeavesForAdmin(mode);
        if (status === 200) {
            if (mode === "waiting")
                setPendingLeavesData(data.response);
            if (mode === "accepted")
                setAcceptedLeavesData(data.response);
            if (mode === "rejected")
                setRejectedLeavesData(data.response);
        }
        console.log(mode, "data", data);
    }

    useEffect(async () => {
        await fetchFacultyLeavesForAdmin("waiting");
        await fetchFacultyLeavesForAdmin("accepted");
        await fetchFacultyLeavesForAdmin("rejected");
    }, [loading]);

    return (
        <div className="flex-[0.8] mt-3 mx-2">
            <div className="space-y-5">
                <div className="flex text-gray-400 items-center space-x-2">
                    <h1>Pending Leave Requests</h1>
                </div>
                <div className="w-full flex flex-col md:flex-row md:justify-center md:space-x-6 p-3 h-fit overflow-auto">
                    <div className={"w-fit"}>
                        <table className="table overflow-x-scroll">
                            <thead className="table-header-group border-2">
                                <th className="border-r-2 px-1">Index</th>
                                <th className="border-r-2 px-1">Start Date</th>
                                <th className="border-r-2 px-1">End Date</th>
                                <th className="border-r-2 px-1">Status</th>
                                <th className="border-r-2 px-1">Reason</th>
                                <th className="border-r-2 px-1">Created By</th>
                                <th className="px-1 border-r-2">Action</th>
                            </thead>
                            <tbody className="border-2">
                                {
                                    pendingleavesData && pendingleavesData.length > 0 &&
                                    pendingleavesData.map((leave, index) => (
                                        <tr className="table-row border-b" key={index}>
                                            <td className="border-r-2 px-1">{index + 1}</td>
                                            <td className="border-r-2 px-1">{new Date(leave.start).toDateString()}</td>
                                            <td className="border-r-2 px-1">{new Date(leave.end).toDateString()}</td>
                                            <td className="border-r-2 px-1">{leave.status}</td>
                                            <td className="border-r-2 px-1">{leave.reason}</td>
                                            <td className="border-r-2 px-1">
                                                <div>
                                                    {leave.faculty.name}<br></br>
                                                    {leave.faculty.designation} - {leave.faculty.department}
                                                </div>
                                            </td>

                                            <td className="border-r-2 px-1">
                                                <button className="px-1 mx-1 rounded z-10 shadow-smborder-t-neutral-200 bg-green-500"
                                                    onClick={() => {handleAcceptRejectLeave(leave._id, 'accepted')}}>
                                                    Accept
                                                </button>
                                                <button className="px-1 mx-1 rounded z-10 shadow-sm border-t-neutral-200 bg-red-500"
                                                    onClick={() => {handleAcceptRejectLeave(leave._id, 'rejected')}}>
                                                    Reject
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex text-gray-400 items-center space-x-2">
                    <h1>Accepted Leave Requests</h1>
                </div>
                <div className="w-full flex flex-col md:flex-row md:justify-center md:space-x-6 p-3 h-fit overflow-auto">
                    <div className={"w-fit"}>
                        <table className="table overflow-x-scroll">
                            <thead className="table-header-group border-2">
                                <th className="border-r-2 px-1">Index</th>
                                <th className="border-r-2 px-1">Start Date</th>
                                <th className="border-r-2 px-1">End Date</th>
                                <th className="border-r-2 px-1">Status</th>
                                <th className="border-r-2 px-1">Reason</th>
                                <th className="border-r-2 px-1">Created By</th>
                            </thead>
                            <tbody className="border-2">
                                {
                                    acceptedLeavesData && acceptedLeavesData.length > 0 &&
                                    acceptedLeavesData.map((leave, index) => (
                                        <tr className="table-row border-b" key={index}>
                                            <td className="border-r-2 px-1">{index + 1}</td>
                                            <td className="border-r-2 px-1">{new Date(leave.start).toDateString()}</td>
                                            <td className="border-r-2 px-1">{new Date(leave.end).toDateString()}</td>
                                            <td className="border-r-2 px-1">{leave.status}</td>
                                            <td className="border-r-2 px-1">{leave.reason}</td>
                                            <td className="border-r-2 px-1">
                                                <div>
                                                    {leave.faculty.name}<br></br>
                                                    {leave.faculty.designation} - {leave.faculty.department}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex text-gray-400 items-center space-x-2">
                    <h1>Rejected Leave Requests</h1>
                </div>
                <div className="w-full flex flex-col md:flex-row md:justify-center md:space-x-6 p-3 h-fit overflow-auto">
                    <div className={"w-fit"}>
                        <table className="table overflow-x-scroll">
                            <thead className="table-header-group border-2">
                                <th className="border-r-2 px-1">Index</th>
                                <th className="border-r-2 px-1">Start Date</th>
                                <th className="border-r-2 px-1">End Date</th>
                                <th className="border-r-2 px-1">Status</th>
                                <th className="border-r-2 px-1">Reason</th>
                                <th className="border-r-2 px-1">Created By</th>
                            </thead>
                            <tbody className="border-2">
                                {
                                    rejectedLeavesData && rejectedLeavesData.length > 0 &&
                                    rejectedLeavesData.map((leave, index) => (
                                        <tr className="table-row border-b" key={index}>
                                            <td className="border-r-2 px-1">{index + 1}</td>
                                            <td className="border-r-2 px-1">{new Date(leave.start).toDateString()}</td>
                                            <td className="border-r-2 px-1">{new Date(leave.end).toDateString()}</td>
                                            <td className="border-r-2 px-1">{leave.status}</td>
                                            <td className="border-r-2 px-1">{leave.reason}</td>
                                            <td className="border-r-2 px-1">
                                                <div>
                                                    {leave.faculty.name}<br></br>
                                                    {leave.faculty.designation} - {leave.faculty.department}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}