import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getNotice } from "../../redux/actions/adminActions";
import Body from "./Body";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { mainPageBody, mainPageContent } from "../../utils/styles";

const AccountHome = () => {
  const dispatch = useDispatch();
 
  useEffect(async () => {
      try {
        await dispatch(getNotice());
      } catch (error) {
        console.error("Error fetching notice:", error);
        alert(`Error occured: ${error.message}`); 
      }
    }, [dispatch]);
  
  return (
    <div className={mainPageBody}>
      <Sidebar />
      <div className={mainPageContent}>
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default AccountHome;
