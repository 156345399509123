import React, { useEffect, useState } from "react";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useDispatch, useSelector } from "react-redux";
import { getSubject } from "../../../redux/actions/adminActions";
import Spinner from "../../../utils/Spinner";
import { SET_ERRORS } from "../../../redux/actionTypes";
import * as classes from "../../../utils/styles";

const Body = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state);
  const [value, setValue] = useState({
    department: "",
    year: "",
  });
  const [search, setSearch] = useState(false);
  const subjects = useSelector((state) => state.admin.subjects);

  useEffect(() => {
    if (Object.keys(store.errors).length !== 0) {
      setError(store.errors);
      setLoading(false);
    }
  }, [store.errors]);

  useEffect(() => {
    if (subjects?.length !== 0) setLoading(false);
  }, [subjects]);

  useEffect(() => {
    dispatch({ type: SET_ERRORS, payload: {} });
  }, [dispatch]);

  return (
    <div className="flex-1 mt-3 px-4 sm:px-6 lg:px-8">
      <div className="space-y-5">
        {/* Header */}
        <div className="flex items-center text-gray-600 space-x-2">
          <MenuBookIcon className="text-2xl" />
          <h1 className="text-2xl font-semibold">All Courses</h1>
        </div>
        {/* Table Container */}
        <div className="bg-white rounded-xl shadow-md p-6 overflow-x-auto">
          <div className={classes.tableDataContainer}>
            <div className={classes.loadingAndError}>
              {loading ? (
                <div className="flex justify-center items-center h-64">
                  <Spinner
                    message="Loading"
                    height={50}
                    width={150}
                    color="#111111"
                    messageColor="blue"
                  />
                </div>
              ) : subjects.length === 0 ? (
                <p className="text-red-500 text-2xl font-bold text-center">
                  No subjects found
                </p>
              ) : (
                <div className={classes.adminData}>
                  {/* Table Header */}
                  <div className="hidden sm:grid grid-cols-6 gap-4 py-2 border-b">
                    <h1 className={`${classes.adminDataHeading} text-center`}>
                      Sr No.
                    </h1>
                    <h1 className={`${classes.adminDataHeading} text-center`}>
                      Subject Code
                    </h1>
                    <h1 className={`${classes.adminDataHeading} text-center`}>
                      Subject Name
                    </h1>
                    <h1 className={`${classes.adminDataHeading} text-center`}>
                      Total Lectures
                    </h1>
                    <h1 className={`${classes.adminDataHeading} text-center`}>
                      Attendance %
                    </h1>
                  </div>

                  {/* Table Rows */}
                  {subjects.map((sub, idx) => (
                    <div
                      key={sub._id}
                      className="grid grid-cols-1 sm:grid-cols-6 gap-4 py-4 border-b hover:bg-gray-50 transition duration-200"
                    >
                      <div className="flex items-center justify-center">
                        <span className="text-center">{idx + 1}</span>
                      </div>
                      <div className="flex items-center justify-center">
                        <span className="text-center">{sub.subjectCode}</span>
                      </div>
                      <div className="flex items-center justify-center">
                        <span className="text-center">{sub.subjectName}</span>
                      </div>
                      <div className="flex items-center justify-center">
                        <span className="text-center">{sub.totalLectures}</span>
                      </div>
                      <div className="flex items-center justify-center">
                        <span
                          className={`text-center font-semibold ${
                            sub.attendancePercentage < 75
                              ? "text-red-600"
                              : "text-green-600"
                          }`}
                        >
                          {sub.attendancePercentage}%
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
