// import React from "react";
// import { Avatar } from "@mui/material";
// import LogoutIcon from "@mui/icons-material/Logout";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// const Header = () => {
//   const user = JSON.parse(localStorage.getItem("user"));
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const logout = () => {
//     dispatch({ type: "LOGOUT" });
//     navigate("/login/facultyLogin");
//   };
//   return (
//     <div className="flex-[0.05] flex justify-between items-center mx-5 my-2">
//       <div className="flex items-center ">
//         <img src="/aim-Logo-removebg-preview.png" alt="" className="h-16" />
//         {/* <h1 className="font-bold text-blue-600 text-sm">CMS</h1> */}
//       </div>
//       <h1 className="font-semibold text-black text-3xl">FACULTY PORTAL</h1>

//       <div className="flex items-center space-x-3">
//         <Avatar
//           src={user.result.avatar}
//           alt={user.result.name.charAt(0)}
//           sx={{ width: 24, height: 24 }}
//           className="border-blue-600 border-2"
//         />
//         <h1>{user.result.username.split(" ")[0]}</h1>
//         <LogoutIcon
//           onClick={logout}
//           className="cursor-pointer hover:scale-125 transition-all "
//         />
//       </div>
//     </div>
//   );
// };

// export default Header;

import React from "react";
import { Avatar } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  // Get the current date
  const currentDate = new Date().toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className="flex-[0.05] flex justify-between items-center mx-5 my-2">
      <div className="flex items-center">
        <img src="/aim-Logo-removebg-preview.png" alt="" className="h-16" />
        {/* <h1 className="font-bold text-blue-600 text-sm">CMS</h1> */}
      </div>
      <h1 className="font-semibold text-black md:text-3xl">FACULTY PORTAL</h1>
      <div className="flex items-center space-x-3">
        <span className="text-sm text-gray-500">{currentDate}</span>
        <Avatar
          src={user.result.avatar}
          alt={user.result.name.charAt(0)}
          sx={{ width: 24, height: 24 }}
          className="border-blue-600 border-2"
        />
        <h1>{user.result.username.split(" ")[0]}</h1>
        <LogoutIcon
          onClick={logout}
          className="cursor-pointer hover:scale-125 transition-all"
        />
      </div>
    </div>
  );
};

export default Header;
