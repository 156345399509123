// client/src/components/faculty/markAttendance/MarkAttendance.js
import React, { useEffect } from "react";
import Body from "./Body";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useDispatch } from "react-redux";
import {
  getAllDepartment,
  getAllSubject,
} from "../../../redux/actions/adminActions";
import { mainPageBody, mainPageContent } from "../../../utils/styles";

const MarkAttendance = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
       
        await dispatch(getAllDepartment());
      } catch (error) {
        alert("Failed to fetch departments. Please try again." + error.message);
        console.error("Error in API call:", error);
      }
    };

    fetchData();
  }, [dispatch]);
    
  return (
    <div className={mainPageBody}>
      <Sidebar />
      <div className={mainPageContent}>
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default MarkAttendance;
