export const adminData =
  "flex flex-col overflow-y-auto scrollbar-thin scrollbar-track-white scrollbar-thumb-black h-[25rem]  shadow-lg pl-5 rounded-md overflow-x-hidden";

export const adminDataBody =
  "grid grid-cols-12 hover:scale-105 transition-all duration-150";

export const mainPageBody = "bg-[#d6d9e0] md:h-screen flex justify-between";
export const mainPageContent = "flex flex-col bg-[#f4f6fa] md:w-[85%] rounded-2xl shadow-2xl space-y-6";

export const adminDataHeading = "font-bold py-2 px-2";
export const adminDataBodyFields = "py-2 px-2";

export const adminFormSubmitButton =
  "bg-red-500 w-fit px-3 h-8 rounded-md text-white hover:scale-105 hover:bg-red-700 transition-all duration-200 ";
export const adminFormClearButton =
  "bg-blue-500 w-fit px-3 h-8 rounded-md text-white hover:scale-105 hover:bg-blue-700 transition-all duration-200";
export const adminFormButton = "self-center space-x-6 mt-3";

export const adminForm0 = "flex flex-col mb-6";
export const adminForm1 = "flex py-10 ml-2 md:ml-10 space-x-28";
export const adminForm2l = "flex flex-col space-y-10";
export const adminForm2r = "flex flex-col space-y-10 pr-6";
export const adminForm3 = "flex bg-gray-100 rounded-md w-fit py-1 mx-2";

export const adminLabel =
  "font-bold md:text-lg shadow-xl bg-gray-700 text-white px-2 py-1 w-fit rounded-l-md";
export const adminDataValue = "font-normal md:text-lg px-2 py-1";
export const adminInput = "px-1 py-1 mx-1 my-1 text-sm w-fit";

export const loadingAndError = "flex justify-center mt-6";

export const profilePageBody = "flex mt-3 flex-col space-y-2 md:space-y-5 overflow-scroll";
export const pageBody = "flex mt-3 flex-col space-y-2 md:space-y-5 overflow-scroll";
export const profileHeaderContainer = "flex space-x-2 md:items-center justify-between mx-4";
export const profileContainer = "flex flex-col max-w-sm md:max-w-full md:justify-center md:items-center bg-white pt-5 pb-10 overflow-y-scroll align-left";
export const pageBodyContentContainer = "flex flex-col max-w-sm md:max-w-full mx-3 bg-white pt-5 pb-10 overflow-y-scroll space-y-3 align-left";
export const profileDataColumnsContainer = "flex flex-col md:flex-row space-y-5 md:space-y-0 w-fit space-x-0 md:space-x-40 mt-4";
export const profileDataColumn = "flex flex-col space-y-5 md:space-y-10";

export const tableDataContainer = "w-max md:mr-6 overflow-x-scroll";
