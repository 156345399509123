import React, { useState } from "react";

const Notice = ({ idx, notice, notFor }) => {
  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  }
  return (
    notFor !== notice.noticeFor && (
      <div className="flex w-full justify-between first:shadow-md py-2 px-2 rounded-lg bg-slate-50 hover:bg-black hover:text-white transition-all duration-200 cursor-pointer h-10 overflow-auto">
        <h1 className="font-bold ml-3 overflow-hidden text-ellipsis w-fit">
          {truncateText(notice.topic, 25)}
        </h1>
        {/* <p className="text-ellipsis w-[25rem] overflow-hidden">
          {notice.content}
        </p> */}
        <p className="whitespace-nowrap">{notice.date}</p>
      </div>
    )
  );
};

export default Notice;
