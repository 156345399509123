// client/src/components/admin/getSubject/Body.js

import React, { useEffect, useState } from "react";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useDispatch, useSelector } from "react-redux";
import { getSubject, getStudentsWithAttendanceAdmin } from "../../../redux/actions/adminActions";
import { MenuItem, Select } from "@mui/material";
import Spinner from "../../../utils/Spinner";
import { SET_ERRORS } from "../../../redux/actionTypes";
import * as classes from "../../../utils/styles";
import { GET_STUDENTS_WITH_ATTENDANCE_ADMIN } from "../../../redux/actionTypes";

const Body = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const departments = useSelector((state) => state.admin.allDepartment);
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state);
  const [value, setValue] = useState({
    department: "",
    year: "",
    section: "",
  });
  const [search, setSearch] = useState(false);
  const [selectedSubjectId, setSelectedSubjectId] = useState("");
  
  const subjects = useSelector((state) => state.admin.subjects);
  const studentsWithAttendance = useSelector((state) => state.admin.studentsWithAttendance);

  useEffect(() => {
    if (Object.keys(store.errors).length !== 0) {
      setError(store.errors);
      setLoading(false);
      alert(`Error occurred: ${store.errors || "An error occurred"}`);
    }
  }, [store.errors]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSearch(true);
    setLoading(true);
    setError({});
    try {
      // Ensure that the formData includes department, year, and section as numbers
      const formData = {
        department: value.department,
        year: Number(value.year),
        section: Number(value.section),
      };
      await dispatch(getSubject(formData));
    } catch (error) {
      setLoading(false);
      setError(error);
      alert(`Error occurred: ${error.message || "Failed to fetch subjects"}`);
    }
  };

  useEffect(() => {
    if (subjects?.length !== 0) setLoading(false);
  }, [subjects]);

  useEffect(() => {
    dispatch({ type: SET_ERRORS, payload: {} });
  }, [dispatch]);

  const handleSubjectChange = async (e) => {
    const subjectId = e.target.value;
    setSelectedSubjectId(subjectId);
   
    setLoading(true);
    setError({});
    try {
      const formData = {
        subjectId: subjectId,
        department: value.department,
        year: Number(value.year),
        section: Number(value.section),
      };
      await dispatch(getStudentsWithAttendanceAdmin(formData));
    } catch (err) {
      setLoading(false);
      setError(err);
      alert(`Error occurred: ${err.message || "Failed to fetch students"}`);
    }
  };

  useEffect(() => {
    console.log("studentsWithAttendance", studentsWithAttendance);
    if (studentsWithAttendance) setLoading(false);
  }, [studentsWithAttendance]);

  return (
    <div className="flex-[0.8] mt-3">
      <div className="space-y-5">
        {/* Header */}
        <div className="flex text-gray-400 items-center space-x-2">
          <MenuBookIcon />
          <h1>All Courses and Attendances</h1>
        </div>

        {/* Main Content */}
        <div className="mr-10 bg-white grid md:grid-cols-4 rounded-xl pt-6 pl-6 h-auto">
          {/* Form to select department, year, and semester */}
          <form
            className="flex flex-col space-y-2 col-span-1"
            onSubmit={handleSubmit}
          >
            <label htmlFor="department">Department</label>
            <Select
              required
              displayEmpty
              sx={{ height: 36, width: 224 }}
              inputProps={{ "aria-label": "Without label" }}
              value={value.department}
              onChange={(e) =>
                setValue({ ...value, department: e.target.value })
              }
            >
              <MenuItem value="">None</MenuItem>
              {departments?.map((dp, idx) => (
                <MenuItem key={idx} value={dp.department}>
                  {dp.department}
                </MenuItem>
              ))}
            </Select>

            <label htmlFor="year">Year</label>
            <Select
              required
              displayEmpty
              sx={{ height: 36, width: 224 }}
              inputProps={{ "aria-label": "Without label" }}
              value={value.year}
              onChange={(e) =>
                setValue({ ...value, year: e.target.value })
              }
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>
              <MenuItem value={2025}>2025</MenuItem>
            </Select>

            <label htmlFor="section">Semester</label>
            <Select
              required
              displayEmpty
              sx={{ height: 36, width: 224 }}
              inputProps={{ "aria-label": "Without label" }}
              value={value.section}
              onChange={(e) =>
                setValue({ ...value, section: e.target.value })
              }
            >
              <MenuItem value="">None</MenuItem>
              {[...Array(8)].map((_, idx) => (
                <MenuItem key={idx} value={idx + 1}>
                  {idx + 1}
                </MenuItem>
              ))}
            </Select>

            <button
              className={`${classes.adminFormSubmitButton} w-56`}
              type="submit"
            >
              Search
            </button>
          </form>

          {/* Table Container */}
          <div className={classes.tableDataContainer}>
            <div className={classes.loadingAndError}>
              {loading && (
                <Spinner
                  message="Loading"
                  height={50}
                  width={150}
                  color="#111111"
                  messageColor="blue"
                />
              )}
              {(error.noSubjectError || error.backendError) && (
                <p className="text-red-500 text-2xl font-bold">
                  {error.noSubjectError || error.backendError}
                </p>
              )}
            </div>

            {/* Display subject list after form submission */}
            {search && !loading && Object.keys(error).length === 0 && subjects?.length !== 0 && (
              <div className={` h-fit `}>
                {/* Subject List Header */}
                <div className="grid grid-cols-7">
                  <h1 className={`${classes.adminDataHeading} col-span-1`}>
                    Sr no.
                  </h1>
                  <h1 className={`${classes.adminDataHeading} col-span-2`}>
                    Subject Code
                  </h1>
                  <h1 className={`${classes.adminDataHeading} col-span-3`}>
                    Subject Name
                  </h1>
                  <h1 className={`${classes.adminDataHeading} col-span-1`}>
                    Total Lectures
                  </h1>
                </div>

                {/* Subject List */}
                {subjects?.map((sub, idx) => (
                  <div
                    key={idx}
                    className={`${classes.adminDataBody} grid-cols-7`}
                  >
                    <h1 className={`col-span-1 ${classes.adminDataBodyFields}`}>
                      {idx + 1}
                    </h1>
                    <h1 className={`col-span-2 ${classes.adminDataBodyFields}`}>
                      {sub.subjectCode}
                    </h1>
                    <h1 className={`col-span-3 ${classes.adminDataBodyFields}`}>
                      {sub.subjectName}
                    </h1>
                    <h1 className={`col-span-1 ${classes.adminDataBodyFields}`}>
                      {sub.totalLectures}
                    </h1>
                  </div>
                ))}

                {/* Divider */}
                <hr className="my-4" />

                {/* Select Subject to View Student Attendance */}
                <div className="flex items-center space-x-4 mt-4">
                  <label htmlFor="subject">Select Subject:</label>
                  <Select
                    required
                    displayEmpty
                    sx={{ height: 36, width: 224 }}
                    inputProps={{ "aria-label": "Without label" }}
                    value={selectedSubjectId}
                    onChange={handleSubjectChange}
                  >
                    <MenuItem value="">None</MenuItem>
                    {subjects?.map((subject, idx) => (
                      <MenuItem key={idx} value={subject._id}>
                        {subject.subjectName}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            {/* Display student attendance percentages */}
            {selectedSubjectId && !loading && Object.keys(error).length === 0 && (
              <div className={` mt-4`}>
                {/* Student List Header */}
                <div className="grid grid-cols-6">
                  <h1 className={`${classes.adminDataHeading} col-span-1`}>
                    Sr no.
                  </h1>
                  <h1 className={`${classes.adminDataHeading} col-span-1`}>
                    Roll No
                  </h1>
                  <h1 className={`${classes.adminDataHeading} col-span-2`}>
                    Name
                  </h1>
                  <h1 className={`${classes.adminDataHeading} col-span-1`}>
                    Semester
                  </h1>
                  <h1 className={`${classes.adminDataHeading} col-span-1`}>
                    Attendance %
                  </h1>
                </div>

                {/* Student List */}
                {studentsWithAttendance?.map((stu, idx) => (
                  <div
                    key={idx}
                    className={`${classes.adminDataBody} grid-cols-6`}
                  >
                    <h1 className={`col-span-1 ${classes.adminDataBodyFields}`}>
                      {idx + 1}
                    </h1>
                    <h1 className={`col-span-1 ${classes.adminDataBodyFields}`}>
                      {stu.rollNo}
                    </h1>
                    <h1 className={`col-span-2 ${classes.adminDataBodyFields}`}>
                      {stu.name}
                    </h1>
                    <h1 className={`col-span-1 ${classes.adminDataBodyFields}`}>
                      {stu.section}
                    </h1>
                    <h1
                      className={`col-span-1 ${classes.adminDataBodyFields} ${
                        stu.attendancePercentage < 75
                          ? "text-red-600"
                          : "text-green-600"
                      }`}
                    >
                      {stu.attendancePercentage}%
                    </h1>
                  </div>
                ))}

                {/* Handle case when no students are found */}
                {studentsWithAttendance?.length === 0 && (
                  <p className="text-center text-gray-500 mt-4">
                    No students found for the selected subject.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        </div>
        </div>
      );
    };


    export default Body;
