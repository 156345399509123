import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AddIcon from "@mui/icons-material/Add";
import BoyIcon from "@mui/icons-material/Boy";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useDispatch } from "react-redux";
import decode from "jwt-decode";

const isNotActiveStyle =
  "flex items-center px-5 gap-3 text-gray-400 hover:text-white transition-all duration-200 ease-in-out capitalize hover:bg-gray-700 py-2 my-1";
const isActiveStyle =
  "flex items-center px-5 gap-3 text-white transition-all duration-200 ease-in-out capitalize hover:bg-gray-700 py-2 my-1";

const Sidebar = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);

  const logout = () => {
    alert("OOPS! Your session expired. Please Login again");
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  useEffect(() => {
    try {
      const token = user?.token;
      if (token) {
        const token = user?.token;
        const decodedToken = decode(token);
        if (decodedToken.exp * 1000 < new Date().getTime()) logout();
      }
    } catch (error) {
      console.error("Error checking token:", error);
      alert("An error occurred while checking the session. Please try again later."+error.message);
    }

  }, [navigate]);

  return (
    <div className={toggle ? "w-12 md:w-auto flex-[1.0] bg-gray-800 min-h-screen h-full" : "w-auto flex-[1.0] bg-gray-800 min-h-screen h-full"}>
      <div className={"toggle absolute left-1 top-1 md:invisible"} onClick={() => {setToggle(prevState => !prevState)}}>
        {toggle ?
        <div className="w-fit rounded bg-white px-2 font-bold">&gt;&gt; Nav</div>
        :
        <div className="w-full rounded bg-white px-2 font-bold">&lt;&lt; Nav</div>
        }
      </div>
      <div className="space-y-8 overflow-y-scroll scrollbar-thin scrollbar-track-white scrollbar-thumb-gray-300 h-full">
        <div className="mt-6 md:mt-0">
          <NavLink
            to="/admin/home"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <HomeIcon className="" />
            <h1 className="font-normal">Dashboard</h1>
          </NavLink>
          <NavLink
            to="/admin/profile"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AssignmentIndIcon className="" />
            <h1 className="font-normal">Profile</h1>
          </NavLink>
        </div>
        <div className="">
          <NavLink
            to="/admin/createNotice"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AddIcon className="" />
            <h1 className="font-normal">Create Notice</h1>
          </NavLink>
        </div>
        {/* <div className="">
          <NavLink
            to="/admin/addadmin"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AddIcon className="" />
            <h1 className="font-normal">Add Admin</h1>
          </NavLink>
          <NavLink
            to="/admin/deleteadmin"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <DeleteIcon className="" />
            <h1 className="font-normal">All Admin</h1>
          </NavLink>
        </div> */}
        <div className="">
          <NavLink
            to="/admin/adddepartment"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AddIcon className="" />
            <h1 className="font-normal">Add Department</h1>
          </NavLink>
          <NavLink
            to="/admin/deletedepartment"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <DeleteIcon className="" />
            <h1 className="font-normal">All Department</h1>
          </NavLink>
        </div>
        <div className="">
          <NavLink
            to="/admin/allfaculty"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <EngineeringIcon className="" />
            <h1 className="font-normal">Our Faculty</h1>
          </NavLink>

          <NavLink
            to="/admin/addfaculty"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AddIcon className="" />
            <h1 className="font-normal">Add Faculty</h1>
          </NavLink>
          <NavLink
            to="/admin/facultyleaves"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AddIcon className="" />
            <h1 className="font-normal">Faculty Leaves</h1>
          </NavLink>
          <NavLink
            to="/admin/deletefaculty"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <DeleteIcon className="" />
            <h1 className="font-normal">All Faculty</h1>
          </NavLink>
        </div>
        <div className="">
          <NavLink
            to="/admin/allaccounts"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AccountBalanceIcon className="" />
            <h1 className="font-normal">Our Accounts</h1>
          </NavLink>

          <NavLink
            to="/admin/addaccount"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AddIcon className="" />
            <h1 className="font-normal">Add Account</h1>
          </NavLink>
          <NavLink
            to="/admin/deleteaccount"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <DeleteIcon className="" />
            <h1 className="font-normal">All Account</h1>
          </NavLink>
        </div>
        <div className="">
          <NavLink
            to="/admin/allstudent"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <BoyIcon className="" />
            <h1 className="font-normal">Our Students</h1>
          </NavLink>

          <NavLink
            to="/admin/addstudent"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AddIcon className="" />
            <h1 className="font-normal">Add Students</h1>
          </NavLink>
          <NavLink
            to="/admin/deletestudent"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <DeleteIcon className="" />
            <h1 className="font-normal">All Student</h1>
          </NavLink>
        </div>
        <div>
          <NavLink
            to="/admin/allsubject"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <BarChartIcon className="" />
            <h1 className="font-normal">View Attendance</h1>
          </NavLink>
        </div>
        <div className="">
          <NavLink
            to="/admin/allsubject"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <MenuBookIcon className="" />
            <h1 className="font-normal">Courses</h1>
          </NavLink>

          <NavLink
            to="/admin/addsubject"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AddIcon className="" />
            <h1 className="font-normal">Add Subject</h1>
          </NavLink>
          <NavLink
            to="/admin/deletesubject"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <DeleteIcon className="" />
            <h1 className="font-normal">All Subject</h1>
          </NavLink>
        </div>
      </div>
      
    </div>
  );
};

export default Sidebar;
