import axios from "axios";

const generateInvoice = async (fee) => {
  // console.log(fee);
  const invoiceData = {
    logo: "https://aim-college-management.s3.ap-south-1.amazonaws.com/aim-logo.png",
    from: "Assam Institute of Management",
    to: fee.student.name,
    currency: "INR",
    number: fee._id,
    payment_terms: "Due on Receipt",
    items: fee.feeItems.map((item) => ({
      name: item.itemName,
      quantity: 1,
      unit_cost: item.itemAmount,
    })),
    fields: {
      tax: "Tax",
      discounts: true,
      shipping: true,
    },
    notes: fee.remarks,
    terms: "Thank you for your business",
  };
  // sk_04wGjJuvtBoe2SgVcRsJXC705IUu7VI2;

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}generate-invoice/${fee.student.name}`,
      invoiceData
    );

    const result = response.data.data;
    if (response.data.success) return result.invoiceUrl;
    console.log("returning empty", response);
    return null;
  } catch (error) {
    console.error("Error generating invoice:", error);
  }
};

export default generateInvoice;
