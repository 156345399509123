// client/src/components/student/BarChart.js
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

export default function BarChart({ attendanceData }) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const calculateOverallPercentage = () => {
    if (!Array.isArray(attendanceData) || attendanceData.length === 0) return 0;

    let totalAttendedLectures = 0;
    let totalLectures = 0;

    attendanceData.forEach(subject => {
      const attendancePercentage = subject.attendancePercentage; // already in percentage
      const totalLecturesPerSubject = subject.totalLectures;
      const attendedLectures = (attendancePercentage / 100) * totalLecturesPerSubject;
      totalAttendedLectures += attendedLectures;
      totalLectures += totalLecturesPerSubject;
    });

    return totalLectures > 0 ? ((totalAttendedLectures / totalLectures) * 100).toFixed(2) : 0;
  };

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");

    const labels = attendanceData?.map((subject) => subject.subjectName);
    const data = attendanceData?.map((subject) => parseFloat(subject.attendancePercentage));

    // Function to generate dynamic colors
    const generateColors = (numColors) => {
      const colors = [];
      for (let i = 0; i < numColors; i++) {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        colors.push(`rgba(${r}, ${g}, ${b}, 0.2)`);
      }
      return colors;
    };

    const barColors = generateColors(data?.length);

    chartInstance.current = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Attendance Percentage",
            data: data,
            backgroundColor: barColors,
          },
        ],
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [attendanceData]);

  const overallPercentage = calculateOverallPercentage();

  return (
    <div>
      <h2 style={{ fontWeight: 'bold', color: 'black' }}>
        Overall Attendance Percentage: {overallPercentage}%
        <a href="/student/subjectlist" className="p-1 bg-blue-400 ml-5 rounded shadow-sm text-white"> Subject-Wise</a>
      </h2>
      <canvas ref={chartRef} />
    </div>
  );
}
