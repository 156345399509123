import React from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { mainPageBody, mainPageContent } from "../../../utils/styles";
import Body from "./Body";

export default function StudentLeaves () {

    return (
        <div className={mainPageBody}>
            <Sidebar/>
            <div className={mainPageContent}>
                <Header/>
                <Body/>
            </div>
        </div>
    );
}