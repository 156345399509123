import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getNotice } from "../../redux/actions/adminActions";

import Body from "./Body";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { mainPageBody, mainPageContent } from "../../utils/styles";

const FacultyHome = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = () => {
      try {
       dispatch(getNotice());
        
      } catch (error) {
        alert("Failed to fetch notice. Please try again." + error.message);
        console.error("Error in API call:", error);
      }
    };  
   fetchData();
}, [dispatch]);


  return (
    <div className={mainPageBody}>
      <Sidebar />
      <div className={mainPageContent}>
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default FacultyHome;
