import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Header from "../Header";
import Sidebar from "../Sidebar";
import Body from "./Body";
import { mainPageBody, mainPageContent } from "../../../utils/styles";
import { getStudentLeaves } from "../../../redux/actions/studentActions";
import { LEAVE_MODES } from "../../../constants";

const Leave = () => {
  return (
    <div className={mainPageBody}>
      <Sidebar />
      <div className={mainPageContent}>
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default Leave;
