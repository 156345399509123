// client/src/redux/actionTypes.js
export const ADMIN_LOGIN = "ADMIN_AUTH";
export const LOGOUT = "LOGOUT";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const ADD_FACULTY = "ADD_FACULTY";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const GET_ALL_FACULTY = "GET_ALL_FACULTY";
export const ADD_SUBJECT = "ADD_SUBJECT";
export const GET_ALL_SUBJECT = "GET_ALL_SUBJECT";
export const ADD_STUDENT = "ADD_STUDENT";
export const GET_ALL_STUDENT = "GET_ALL_STUDENT";
export const GET_STUDENT = "GET_STUDENT";
export const GET_FACULTY = "GET_FACULTY";
export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_SUBJECT = "GET_SUBJECT";
export const GET_MARKS = "GET_MARKS";
export const GET_ALL_ADMIN = "GET_ALL_ADMIN";
export const GET_ALL_DEPARTMENT = "GET_ALL_DEPARTMENT";
export const GET_ALL_FEE_REQUESTS = "GET_ALL_FEE_REQUESTS";
export const GET_NEW_FEE_REQUESTS = "GET_NEW_FEE_REQUESTS";
export const GET_PENDING_FEE_REQUESTS = "GET_PENDING_FEE_REQUESTS";
export const GET_PARTIAL_FEE_REQUESTS = "GET_PARTIAL_FEE_REQUESTS";
export const GET_OLD_FEE_REQUESTS = "GET_OLD_FEE_REQUESTS";
export const GET_UNPAID_FEE_REQUESTS = "GET_UNPAID_FEE_REQUESTS";
export const GET_PAID_FEE_REQUESTS = "GET_PAID_FEE_REQUESTS";
export const SET_ERRORS = "SET_ERRORS";
export const FACULTY_LOGIN = "FACULTY_LOGIN";
export const ACCOUNT_LOGIN = "ACCOUNT_LOGIN";
export const UPDATE_FACULTY = "UPDATE_FACULTY";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const ADD_TEST = "ADD_TEST";
export const GET_TEST = "GET_TEST";
export const MARKS_UPLOADED = "MARKS_UPLOADED";
export const ATTENDANCE_MARKED = "ATTENDANCE_MARKED";
export const STUDENT_LOGIN = "STUDENT_LOGIN";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const TEST_RESULT = "TEST_RESULT";
export const ATTENDANCE = "ATTENDANCE";
export const GET_ADMIN = "GET_ADMIN";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const DELETE_FACULTY = "DELETE_FACULTY";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const CREATE_NOTICE = "CREATE_NOTICE";
export const CREATE_FEE_REQUEST = "CREATE_FEE_REQUEST";
export const ACCEPT_FEE_REQUEST = "ACCEPT_FEE_REQUEST";
export const PARTIAL_ACCEPT_FEE_REQUEST = "PARTIAL_ACCEPT_FEE_REQUEST";
export const MODIFY_FEE_REQUEST = "MODIFY_FEE_REQUEST";
export const DELETE_FEE_REQUEST = "DELETE_FEE_REQUEST";
export const UPDATE_FEE_REQUEST = "UPDATE_FEE_REQUEST";
export const GET_NOTICE = "GET_NOTICE";
export const GET_STUDENT_LEAVE = "GET_STUDENT_LEAVE";
export const GET_FACULTY_LEAVE = "GET_FACULTY_LEAVE";
export const CREATE_STUDENT_LEAVE = "CREATE_STUDENT_LEAVE";
export const CREATE_FACULTY_LEAVE = "CREATE_FACULTY_LEAVE";
export const DELETE_STUDENT_LEAVE = "DELETE_STUDENT_LEAVE";
export const DELETE_FACULTY_LEAVE = "DELETE_FACULTY_LEAVE";
export const PAGE_REFRESH = "PAGE_REFRESH";
export const GET_STUDENTS_WITH_ATTENDANCE = "GET_STUDENTS_WITH_ATTENDANCE";
export const GET_STUDENTS_WITH_ATTENDANCE_ADMIN = "GET_STUDENTS_WITH_ATTENDANCE_ADMIN";
