import React, { useState, useEffect } from "react";
import HomeIcon from "@mui/icons-material/Home";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BoyIcon from "@mui/icons-material/Boy";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useSelector } from "react-redux";
import Notice from "../notices/Notice";
import ShowNotice from "../notices/ShowNotice";
import ReplyIcon from "@mui/icons-material/Reply";
import PieChart from "./PieChart";
import axios from "axios";
import { pageBody, pageBodyContentContainer } from "../../utils/styles";

const Body = () => {
  const [open, setOpen] = useState(false);
  const [openNotice, setOpenNotice] = useState({});
  const [subjectAttendances, setSubjectAttendances] = useState({ result: [] });
  const notices = useSelector((state) => state.admin.notices);
  const students = useSelector((state) => state.admin.allStudent);
  const faculties = useSelector((state) => state.admin.allFaculty);
  const admins = useSelector((state) => state.admin.allAdmin);
  const subjects = useSelector((state) => state.admin.allSubject);

  const fetchAllSubjectAttendance = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/admin/getsubjectwiseattendance`
      );

      console.log("All Subject Attendance response:", response.data);
      let data = response.data.response;
      setSubjectAttendances(data);
    } catch (error) {
      console.error("Error fetching subject-wise attendance:", error);
    }
  };

  useEffect(() => {
    fetchAllSubjectAttendance();
  }, []);

  return (
    <div className={pageBody}>
      <div className="flex text-gray-400 items-center space-x-2">
        <HomeIcon />
        <h1>Dashboard</h1>
      </div>
      <div className={pageBodyContentContainer}>
        <div className="grid md:grid-cols-4 gap-4">
          <div className="bg-blue-500 max-w-72 p-4 rounded-xl shadow-lg text-white">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-4xl font-bold">{students?.length}</h2>
                <p>Total Students</p>
              </div>
              <BoyIcon sx={{ fontSize: 40 }} />
            </div>
          </div>
          <div className="bg-red-500 max-w-72 p-4 rounded-xl shadow-lg text-white">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-4xl font-bold">{faculties?.length}</h2>
                <p>Total Staffs</p>
              </div>
              <EngineeringIcon sx={{ fontSize: 40 }} />
            </div>
          </div>
          <div className="bg-yellow-500 max-w-72 p-4 rounded-xl shadow-lg text-white">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-4xl font-bold">{subjects?.length}</h2>
                <p>Total Courses</p>
              </div>
              <MenuBookIcon sx={{ fontSize: 40 }} />
            </div>
          </div>
          <div className="bg-green-500 max-w-72 p-4 rounded-xl shadow-lg text-white">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-4xl font-bold">{admins?.length}</h2>
                <p>Total Admins</p>
              </div>
              <SupervisorAccountIcon sx={{ fontSize: 40 }} />
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
          <div className="bg-white h-[17rem] md:w-1/3 rounded-xl shadow-lg flex flex-col pt-3 space-y-4">
            <div className="flex px-3">
              {open && (
                <ReplyIcon
                  onClick={() => setOpen(false)}
                  className="cursor-pointer"
                />
              )}
              <h1 className="font-bold text-xl w-full text-center">Notices</h1>
            </div>
            <div className="mx-5 mt-5 space-y-3 overflow-y-auto h-[12rem]">
              {!open ? (
                notices?.map((notice, idx) => (
                  <div
                    key={idx}
                    onClick={() => {
                      setOpen(true);
                      setOpenNotice(notice);
                    }}
                  >
                    <Notice idx={idx} notice={notice} notFor="" />
                  </div>
                ))
              ) : (
                <ShowNotice notice={openNotice} />
              )}
            </div>
          </div>

          <div className="bg-white h-[17rem] md:w-1/3 rounded-xl shadow-lg flex flex-col pt-3">
            <PieChart data={subjects} />
          </div>

          <div className="bg-white h-[17rem] md:w-1/3 rounded-xl shadow-lg flex flex-col pt-3 p-4 space-y-3 overflow-y-auto">
            <h1 className="font-bold text-xl text-center">
              Subject-wise Classes
            </h1>

            {subjectAttendances.result.length > 0 ? (
              <table className="min-w-full text-left text-sm mt-2">
                <thead>
                  <tr className="border-b">
                    <th className="py-2 px-3">Department</th>
                    <th className="py-2 px-3">Subject Name</th>
                    <th className="py-2 px-3">Total Classes</th>
                  </tr>
                </thead>
                <tbody>
                  {subjectAttendances.result.map((subject, index) => (
                    <tr key={index} className="border-b">
                      <td className="py-1 px-3">{subject.department}</td>
                      <td className="py-1 px-3">{subject.subjectName}</td>
                      <td className="py-1 px-3">{subject.totalLectures}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-center mt-2 text-gray-500">
                No data available.
              </p>
            )}
            <a href="/admin/allsubject" className="text-blue-400 rounded bg-gray-100 p-1"> View Student Attendances </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
