import React, { useEffect, useState } from "react";
import BoyIcon from "@mui/icons-material/Boy";
import { useDispatch, useSelector } from "react-redux";
import { getStudent } from "../../../redux/actions/facultyActions";
import { MenuItem, Select } from "@mui/material";
import Spinner from "../../../utils/Spinner";
import * as classes from "../../../utils/styles";
import { SET_ERRORS } from "../../../redux/actionTypes";
import { createFeeRequest } from "../../../redux/actions/accountActions";

const Body = () => {
  const dispatch = useDispatch();
  const departments = useSelector((state) => state.admin.allDepartment);

  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state);
  const [checkedValue, setCheckedValue] = useState([]);
  const [amount, setAmount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [feeItems, setFeeItems] = useState([]);
  const [items, setItems] = useState({});
  const [value, setValue] = useState({
    department: "",
    year: "",
    section: "",
  });
  const [search, setSearch] = useState(false);

  useEffect(() => {
    if (Object.keys(store.errors).length !== 0) {
      // setError(store.errors);
      // setLoading(false);
      setValue({ department: "", year: "", section: "" });
    }
  }, [store.errors]);

  const handleInputChange = (e) => {
    const tempCheck = checkedValue;
    let index;
    if (e.target.checked) {
      tempCheck.push(e.target.value);
    } else {
      index = tempCheck.indexOf(e.target.value);
      tempCheck.splice(index, 1);
    }
    setCheckedValue(tempCheck);
  };

  const handleAddFeeItem = (e) => {
    e.preventDefault();
    setFeeItems([...feeItems, items])
    setItems({
      itemName: "",
      itemAmount: 0,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearch(true);
    setLoading(true);
    setError({});
    try {
      dispatch(getStudent(value));
    } catch (error) {
      alert("An error occurred while fetching the student." + error.message);
      console.log(error);
    }finally {
      setLoading(false);
    }
  };

  const students = useSelector((state) => state.admin.students);

  const sendFeeReq = (e) => {
    setError({});
    // console.log("Code reached sendFeeReq -> ", checkedValue, amount, remarks);
    if (feeItems.length === 0) {
      alert('add atleast 1 fee items')
    }
    else {
      try {
        dispatch(createFeeRequest({ checkedValue, amount, remarks, feeItems}));
      } catch (error) {
        alert("An error occurred while creating the fee request." + error.message);
        console.log(error);
      }finally {
        setLoading(false);
    }
  };
}

  // useEffect(() => {
  //   if (store.errors || store.account.feeAdded) {
  //     setLoading(false);
  //     if (store.account.feeAdded) {
  //       setValue({ department: "", year: "", section: "" });
  //       setAmount("");
  //       setRemarks("");
  //       setSearch(false);
  //       dispatch({ type: SET_ERRORS, payload: {} });
  //       // dispatch({ type: ATTENDANCE_MARKED, payload: false });
  //     }
  //   } else {
  //     setLoading(true);
  //   }
  // }, [store.errors, store.account.feeAdded]);

  useEffect(() => {
    let isMounted = true;

    if (store.errors || store.account.feeAdded) {
      if (isMounted) {
        setLoading(false);
      }
      if (store.account.feeAdded) {
        setValue({ department: "", year: "", section: "" });
        setAmount("");
        setRemarks("");
        setSearch(false);
        dispatch({ type: SET_ERRORS, payload: {} });
      }
    } else {
      if (isMounted) {
        setLoading(true);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [store.errors, store.account.feeAdded]);

  useEffect(() => {
    if (store.account.feeAdded) {
      setValue({ department: "", year: "", section: "" });
    }
  }, [store.account.feeAdded]);

  useEffect(() => {
    if (students?.length !== 0) setLoading(false);
  }, [students]);

  useEffect(() => {
    dispatch({ type: SET_ERRORS, payload: {} });
  }, []);

  console.log(feeItems);

  return (
    <div className="flex-[0.8] mt-3">
      <div className="space-y-5">
        <div className="flex text-gray-400 items-center space-x-2">
          <BoyIcon />
          <h1>All Students</h1>
        </div>
        <div className=" mr-10 bg-white grid md:grid-cols-4 rounded-xl pt-6 pl-6 h-[29.5rem] overflow-auto">
          <form
            className="flex flex-col space-y-2 col-span-1"
            onSubmit={handleSubmit}
          >
            <label htmlFor="department">Department</label>
            <Select
              required
              displayEmpty
              sx={{ height: 36, width: 224 }}
              inputProps={{ "aria-label": "Without label" }}
              value={value.department}
              onChange={(e) =>
                setValue({ ...value, department: e.target.value })
              }
            >
              <MenuItem value="">None</MenuItem>
              {departments?.map((dp, idx) => (
                <MenuItem key={idx} value={dp.department}>
                  {dp.department}
                </MenuItem>
              ))}
            </Select>
            <label htmlFor="year">Year</label>
            <Select
              required
              displayEmpty
              sx={{ height: 36, width: 224 }}
              inputProps={{ "aria-label": "Without label" }}
              value={value.year}
              onChange={(e) => setValue({ ...value, year: e.target.value })}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
              <MenuItem value="2025">2025</MenuItem>
            </Select>
            <label htmlFor="section">Semester</label>
            <Select
              required
              displayEmpty
              sx={{ height: 36, width: 224 }}
              inputProps={{ "aria-label": "Without label" }}
              value={value.section}
              onChange={(e) => setValue({ ...value, section: e.target.value })}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="7">7</MenuItem>
              <MenuItem value="8">8</MenuItem>
            </Select>

            <button
              className={`${classes.adminFormSubmitButton} w-56`}
              type="submit"
            >
              Search
            </button>
          </form>
          <div className={classes.tableDataContainer}>
            <div className={classes.loadingAndError}>
              {loading && (
                <Spinner
                  message="Loading"
                  height={50}
                  width={150}
                  color="#111111"
                  messageColor="blue"
                />
              )}
              {(error.noStudentError || error.backendError) && (
                <p className="text-red-500 text-2xl font-bold">
                  {error.noStudentError || error.backendError}
                </p>
              )}
            </div>
            {search &&
              !loading &&
              Object.keys(error).length === 0 &&
              students?.length !== 0 && (
                <div className={`${classes.adminData} h-fit `}>
                  <div className="grid grid-cols-7">
                    <h1 className={`col-span-1 ${classes.adminDataHeading}`}>
                      Select
                    </h1>
                    <h1 className={`col-span-1 ${classes.adminDataHeading}`}>
                      Sr no.
                    </h1>
                    <h1 className={`col-span-2 ${classes.adminDataHeading}`}>
                      Name
                    </h1>
                    <h1 className={`col-span-2 ${classes.adminDataHeading}`}>
                      Username
                    </h1>

                    <h1 className={`col-span-1 ${classes.adminDataHeading}`}>
                      Semester
                    </h1>
                  </div>
                  {students?.map((stu, idx) => (
                    <div
                      key={idx}
                      className={`${classes.adminDataBody} grid-cols-7`}
                    >
                      <input
                        onChange={handleInputChange}
                        value={stu._id}
                        className="col-span-1 border-2 w-16 h-4 mt-3 px-2 "
                        type="checkbox"
                      />
                      <h1
                        className={`col-span-1 ${classes.adminDataBodyFields}`}
                      >
                        {idx + 1}
                      </h1>
                      <h1
                        className={`col-span-2 ${classes.adminDataBodyFields}`}
                      >
                        {stu.name}
                      </h1>
                      <h1
                        className={`col-span-2 ${classes.adminDataBodyFields}`}
                      >
                        {stu.username}
                      </h1>

                      <h1
                        className={`col-span-1 ${classes.adminDataBodyFields}`}
                      >
                        {stu.section}
                      </h1>
                    </div>
                  ))}
                </div>
              )}
            {search && Object.keys(error).length === 0 && (
              <div className="w-full flex flex-col gap-3 items-center justify-center mt-5">
                <form onSubmit={handleAddFeeItem}>
                  <label>
                    Name:
                    <input
                      value={items.itemName}
                      required
                      name="itemName"
                      onChange={(e) => {
                        setItems({ ...items, itemName: e.target.value });
                      }}
                      type="text"
                      className="border-[1px] border-black"
                    />
                  </label>
                  <label>
                    Amount:
                    <input
                      value={items.itemAmount}
                      required
                      name="itemAmount"
                      onChange={(e) => {
                        setItems({ ...items, itemAmount: e.target.value });
                      }}
                      type="number"
                      className="border-[1px] border-black"
                    />
                  </label>
                  <button
                    type="submit"
                    className="bg-blue-500 px-4 py-1 rounded text-white hover:bg-blue-700"
                  >
                    Add
                  </button>
                </form>
                <table>
                  <th>
                    <td>Name</td>
                    <td>Amount</td>
                  </th>
                  {feeItems.map((el, index) => 
                    <tr key={index}>
                      <td>{el.itemName}</td>
                      <td>{el.itemAmount}</td>
                    </tr>
                )}</table>
                <div>
                  <label className="font-bold text-lg">Total</label>
                  <input
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    value={amount}
                    className="border-2 border-black"
                    type="number"
                    required
                  />
                </div>
                <div>
                  <label className="font-bold text-lg">Remarks</label>
                  <textarea
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                    value={remarks}
                    className="border-2 border-black"
                    required
                  />
                </div>

                <button
                  onClick={sendFeeReq}
                  className={`${classes.adminFormSubmitButton} bg-blue-500`}
                >
                  Send
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
