import React, { useEffect } from "react";
import Body from "./Body";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getOldFeeRequests } from "../../../redux/actions/accountActions";
import { mainPageBody, mainPageContent } from "../../../utils/styles";

const Completed = () => {
  const store = useSelector((state) => state);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOldFeeRequests("paid"));
  }, [dispatch, store.account.feeAdded]);
  return (
    <div className={mainPageBody}>
      <Sidebar />
      <div className={mainPageContent}>
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default Completed;
