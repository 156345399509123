import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getUnpaidFeeRequests,
  getPaidFeeRequests,
} from "../../../redux/actions/studentActions";

import Header from "../Header";
import Sidebar from "../Sidebar";
import Body from "./Body";
import { mainPageBody, mainPageContent } from "../../../utils/styles";

const FeeReq = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchFeeRequests = async () => {
      try {
        await dispatch(getUnpaidFeeRequests(user.result._id));

        await dispatch(getPaidFeeRequests(user.result._id));
      } catch (error) {
        alert("Failed to fetch fee requests. Please try again." + error.message);
        console.error("Error during fetching fee requests:", error); 
      }
    };

    fetchFeeRequests();
  }, [dispatch]);

  return (
    <div className={mainPageBody}>
      <Sidebar />
      <div className={mainPageContent}>
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default FeeReq;
