import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const id = user?.result._id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCheckHandler = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}api/admin/isadmin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: id }),
        }
      );
      if (!res.ok) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
    } catch (err) {}
  };

  useEffect(() => {
    handleCheckHandler();
  });

  const accessibleRoute = user?.token ? (
    children
  ) : (
    <Navigate to="/login" replace={true} />
  );

  return accessibleRoute;
};

export default ProtectedRoute;
