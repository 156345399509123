import React, { useState } from "react";

import { Drawer, Button, Placeholder } from "rsuite";
import "rsuite/Drawer/styles/index.css";

import { useDispatch } from "react-redux";
import {
  acceptFeeRequest,
  modifyFeeRequest,
  partialAcceptFeeRequest,
} from "../../../redux/actions/accountActions";
import { useNavigate } from "react-router-dom";

function Card({ fee, openWithHeader, setOpenWithHeader }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [remark, setRemark] = useState("");
  console.log(fee);

  const handleSettlementAccept = async () => {
    try {
      await dispatch(acceptFeeRequest({ fee: fee._id, remarks: remark }));
      alert("Fee Request Accepted");
    } catch (error) {
      alert("Fee Request Accept Error: "+error.message);
      console.log(error);
    }
  };

  const handleSettlementPartialAccept = async() => {
    try {
      await dispatch(partialAcceptFeeRequest({ fee: fee._id, remarks: remark }));
      alert("Partial Fee Request Accepted");
    } catch (error) {
      alert("Partial Fee Request Error: "+error.message);
      console.log(error);
    }
   
  };
  
  const handleSettlementReject = async() => {
    try {
      await dispatch(modifyFeeRequest({ fee: fee._id, remarks: remark }));
      alert("Fee Request Rejected and send to pending");
    } catch (error) {
      alert("Fee Request Rejected Error: "+ error.message);
      console.log(error);
    }
  };

  return (
    <div className="w-[200px] h-fit bg-white drop-shadow-lg flex flex-col justify-between gap-5 p-3">
      <div className="grid grid-cols-1">
        <div className="flex text-[10px] gap-4">
          <label className="font-bold" htmlFor="">
            Id
          </label>
          <p>{fee.student._id}</p>
        </div>
        <div className="flex text-[10px] gap-4">
          <label className="font-bold" htmlFor="">
            Amount
          </label>
          <p>{fee.amount}</p>
        </div>
        <div className="flex text-[10px] gap-4">
          <label className="font-bold" htmlFor="">
            Date of Payment
          </label>
          <p>{fee.createdDate}</p>
        </div>
        {/* <div className="flex text-[10px] gap-4">
          <label className="font-bold" htmlFor="">
            Proof
          </label>
          <p>{fee.proof}</p>
        </div> */}
        <div className="flex text-[10px] gap-4">
          <label className="font-bold" htmlFor="">
            Remarks
          </label>
          <p>{fee.remarks}</p>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <button
          onClick={() => setOpenWithHeader(true)}
          className="text-sm text-white bg-blue-500 rounded px-4 py-1"
        >
          Settle Now
        </button>
      </div>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Drawer.Body>
          <Placeholder.Paragraph />
        </Drawer.Body>
      </Drawer>

      <Drawer open={openWithHeader} onClose={() => setOpenWithHeader(false)}>
        <Drawer.Header>
          <Drawer.Title>Fee Settlement</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpenWithHeader(false)}>Cancel</Button>
            {/* <Button
              onClick={() => setOpenWithHeader(false)}
              appearance="primary"
            >
              Confirm
            </Button> */}
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <table className="border-2">
            <thead className="border-2">
              <tr className="border-2 text-sm">
                <td className="border-2 px-2">Sr. no.</td>
                <td className="border-2 px-2">Fee Type Name</td>
                <td className="border-2 px-2">Fee Amount</td>
                <td className="border-2 px-2">Paid Amount</td>
                <td className="border-2 px-2">Transaction No.</td>
              </tr>
            </thead>
            <tbody>
              {fee?.feeItems.map((el, index) => (
                <tr className="text-sm" key={index}>
                  <td className="border-2 px-2">{index + 1}</td>
                  <td className="border-2 px-2">{el.itemName}</td>
                  <td className="border-2 px-2">{el.itemAmount}</td>
                  <td className="border-2 px-2">{el.paidAmount}</td>
                  <td className="border-2 px-2">{el.txNo}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <label htmlFor="remark">Remark</label>
          <textarea
            id="remark"
            name="remark"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            className="border-[1px] rounded border-neutral-200 focus:outline-none p-2 w-full"
            type="text"
          />
          <div className="flex justify-between py-5">
            <div className="flex gap-5">
              <button
                onClick={handleSettlementAccept}
                className="bg-blue-500 px-4 py-1 text-sm text-white rounded"
              >
                Accept
              </button>
              <button
                onClick={handleSettlementPartialAccept}
                className="bg-blue-500 px-4 py-1 text-sm text-white rounded"
              >
                Partial Accept
              </button>
            </div>
            <button
              onClick={handleSettlementReject}
              className="bg-red-500 px-4 py-1 text-sm text-white rounded"
            >
              Reject
            </button>
          </div>
        </Drawer.Body>
      </Drawer>
    </div>
  );
}

export default Card;
