import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAccount,
  deleteFaculty,
  getAccount,
  getFaculty,
} from "../../../redux/actions/adminActions";
import { MenuItem, Select } from "@mui/material";
import Spinner from "../../../utils/Spinner";
import * as classes from "../../../utils/styles";
import {
  DELETE_ACCOUNT,
  DELETE_FACULTY,
  SET_ERRORS,
} from "../../../redux/actionTypes";

const Body = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state);
  const [checkedValue, setCheckedValue] = useState([]);

  useEffect(() => {
    if (Object.keys(store.errors).length !== 0) {
      setError(store.errors);
      setLoading(false);
      alert(`Error: ${store.errors || "An error occurred."}`);
    }
  }, [store.errors]);

  const handleInputChange = async (e) => {
    const tempCheck = checkedValue;
    let index;

    if (e.target.checked) {
      tempCheck.push(e.target.value);
    } else {
      index = tempCheck.indexOf(e.target.value);
      tempCheck.splice(index, 1);
    }
    setCheckedValue(tempCheck);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError({});
    try {
      await dispatch(getAccount()); // Added try-catch for API call
    } catch (error) {
      alert(`Error: ${error.message || "Failed to fetch accounts."}`);
      
    }finally {  
      setLoading(false);  
    }
  };

  const accounts = useSelector((state) => state.admin.accounts);

  const dltAccount = async (e) => {
    setError({});
    setLoading(true);

    try {
      await dispatch(deleteAccount(checkedValue)); // Added try-catch for API call
    } catch (error) {
      alert(`Error: ${error.message || "Failed to delete account(s)."}`);
      
    }finally {  
      setLoading(false);
    }
  };

  useEffect(() => {
    if (store.admin.facultyDeleted) {
      setLoading(false);
      dispatch({ type: DELETE_ACCOUNT, payload: false });
    }
  }, [store.admin.accountDeleted]);

  useEffect(() => {
    if (accounts?.length !== 0) setLoading(false);
  }, [accounts]);

  useEffect(() => {
    handleSubmit();
    dispatch({ type: SET_ERRORS, payload: {} });
  }, []);

  return (
    <div className={classes.pageBody}>
      <div className="flex text-gray-400 items-center space-x-2">
        <DeleteIcon />
        <h1>Delete Faculty</h1>
      </div>
      <div className={classes.pageBodyContentContainer}>
        <div className={classes.tableDataContainer}>
          {!loading &&
            Object.keys(error).length === 0 &&
            accounts?.length !== 0 && (
              <div className={`${classes.adminData} h-fit `}>
                <div className="grid grid-cols-8">
                  <h1 className={`col-span-1 ${classes.adminDataHeading}`}>
                    Select
                  </h1>
                  <h1 className={`col-span-1 ${classes.adminDataHeading}`}>
                    Sr no.
                  </h1>
                  <h1 className={`col-span-2 ${classes.adminDataHeading}`}>
                    Name
                  </h1>
                  <h1 className={`col-span-2 ${classes.adminDataHeading}`}>
                    Username
                  </h1>

                  <h1 className={`col-span-2 ${classes.adminDataHeading}`}>
                    Email
                  </h1>
                </div>
                {accounts?.map((adm, idx) => (
                  <div
                    key={idx}
                    className={`${classes.adminDataBody} grid-cols-8`}
                  >
                    <input
                      onChange={handleInputChange}
                      value={adm._id}
                      className="col-span-1 border-2 w-16 h-4 mt-3 px-2 "
                      type="checkbox"
                    />
                    <h1 className={`col-span-1 ${classes.adminDataBodyFields}`}>
                      {idx + 1}
                    </h1>
                    <h1 className={`col-span-2 ${classes.adminDataBodyFields}`}>
                      {adm.name}
                    </h1>
                    <h1 className={`col-span-2 ${classes.adminDataBodyFields}`}>
                      {adm.username}
                    </h1>

                    <h1 className={`col-span-2 ${classes.adminDataBodyFields}`}>
                      {adm.email}
                    </h1>
                  </div>
                ))}
              </div>
            )}
          {Object.keys(error).length === 0 && (
            <div className="space-x-3 flex items-center justify-center mt-5">
              <button
                onClick={dltAccount}
                className={`${classes.adminFormSubmitButton} bg-blue-500`}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Body;
