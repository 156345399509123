import React, { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import Calendar from "react-calendar";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BoyIcon from "@mui/icons-material/Boy";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import Notice from "../notices/Notice";
import ReplyIcon from "@mui/icons-material/Reply";
import ShowNotice from "../notices/ShowNotice";
import {
  pageBody,
  pageBodyContentContainer,
  profilePageBody,
} from "../../utils/styles";
import axios from "axios";
const Body = () => {
  const [value, onChange] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [openNotice, setOpenNotice] = useState({});
  const [students, setStudents] = useState([]);
  const [staffs, setStaffs] = useState({
    admins: [],
    faculties: [],
    accounts: [],
  });
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [subjects, setSubjects] = useState([]);
  const notices = useSelector((state) => state.admin.notices);

  const fetchAllStudents = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/account/getallstudent`
      );

      console.log("total students", response.data);
      setStudents(response.data.response.students);
    } catch (error) {
      console.error("Error fetching total tests:", error);
    }
  };

  const fetchAllStaffs = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/account/getallstaffs`
      );
      console.log("total staffs", response.data);
      setStaffs(response.data.response.allStaffs);
    } catch (error) {
      console.error("Error fetching staffs:", error);
    }
  };

  const fetchAllSubjects = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/account/getallsubjects`
      );
      console.log("total subjects", response.data);
      setSubjects(response.data.response.subjects);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const fetchAllAccounts = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/account/getallascounts`
      );
      console.log("total accounts", response.data);
      setTotalAccounts(response.data.response.accounts);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  const totalStaffs =
    (staffs.admins?.length || 0) +
    (staffs.faculties?.length || 0) +
    (staffs.accounts?.length || 0);

  useEffect(() => {
    fetchAllStudents();
    fetchAllStaffs();
    fetchAllSubjects();
    fetchAllAccounts();
  }, []);

  return (
    <div className={pageBody}>
      <div className="flex text-gray-400 items-center space-x-2">
        <HomeIcon />
        <h1>Dashboard</h1>
      </div>
      <div className={pageBodyContentContainer}>
        <div className="grid md:grid-cols-4 gap-4">
          <div className="bg-blue-500 max-w-72 p-4 rounded-xl shadow-lg text-white">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-4xl font-bold">{students.length}</h2>
                <p>Total Students</p>
              </div>
              <BoyIcon sx={{ fontSize: 40 }} />
            </div>
          </div>
          <div className="bg-red-500 max-w-72 p-4 rounded-xl shadow-lg text-white">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-4xl font-bold">{totalStaffs}</h2>
                <p>Total Staffs</p>
              </div>
              <EngineeringIcon sx={{ fontSize: 40 }} />
            </div>
          </div>
          <div className="bg-yellow-500 max-w-72 p-4 rounded-xl shadow-lg text-white">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-4xl font-bold">{subjects.length}</h2>
                <p>Total Courses</p>
              </div>
              <MenuBookIcon sx={{ fontSize: 40 }} />
            </div>
          </div>
          <div className="bg-green-500 max-w-72 p-4 rounded-xl shadow-lg text-white">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-4xl font-bold">{totalAccounts.length}</h2>
                <p>Total Accounts</p>
              </div>
              <SupervisorAccountIcon sx={{ fontSize: 40 }} />
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
          <div className="flex flex-col space-y-4 md:w-2/6">
            <div className="bg-white h-[17rem] rounded-xl shadow-lg">
              <Calendar onChange={onChange} value={value} />
            </div>
          </div>
          <div className="bg-white h-[17rem] w-full rounded-xl shadow-lg flex flex-col  pt-3">
            <div className="flex px-3">
              {open && (
                <ReplyIcon
                  onClick={() => setOpen(false)}
                  className="cursor-pointer"
                />
              )}
              <h1 className="font-bold text-xl w-full text-center">Notices</h1>
            </div>
            <div className="mx-5 mt-5 space-y-3 overflow-y-auto h-[12rem]">
              {!open ? (
                notices?.map((notice, idx) => (
                  <div
                    onClick={() => {
                      setOpen(true);
                      setOpenNotice(notice);
                    }}
                    className=""
                  >
                    <Notice idx={idx} notice={notice} notFor="student" />
                  </div>
                ))
              ) : (
                <ShowNotice notice={openNotice} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
