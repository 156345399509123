import React from "react";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import SecurityUpdateIcon from "@mui/icons-material/SecurityUpdate";
import Data from "./Data";
import { useNavigate } from "react-router-dom";
import { profileContainer, profileDataColumn, profileDataColumnsContainer, profileHeaderContainer, profilePageBody } from "../../../utils/styles";
const Body = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  return (
    <div className={profilePageBody}>
      <div className={profileHeaderContainer}>
        <div className="flex space-x-2 text-gray-400">
          <AssignmentIndIcon />
          <h1>Profile</h1>
        </div>
        <div
          onClick={() => navigate("/account/update")}
          className="flex space-x-2 cursor-pointer">
          <SecurityUpdateIcon />
          <h1 className="font-bold">Update</h1>
        </div>
      </div>
      <div className={profileContainer}>
        <div className="w-fit "> <img className="rounded-full w-16 h-16" src={user.result.avatar} alt="avatar" /></div>
        <div className={profileDataColumnsContainer}>
          <div className={profileDataColumn}>
            <Data label="Name" value={user.result.name} />
            <Data label="Email" value={user.result.email} />
            <Data label="Username" value={user.result.username} />
            <Data label="Department" value={user.result.department} />
          </div>
          <div className={profileDataColumn}>
            <Data label="DOB" value={user.result.dob} />
            <Data label="Joining Year" value={user.result.joiningYear} />
            <Data label="Contact Number" value={user.result.contactNumber} />
            <Data label="Designation" value={user.result.designation} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
